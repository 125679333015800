define('ember-do-forms/utils/set-data-test-selector', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = setDataTestSelector;
  var isBlank = Ember.isBlank,
      set = Ember.set;


  // The idea is to pass down data-test-* attributes
  // (for usage with ember-test-selector) to child components.
  function setDataTestSelector(component) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    options.testSelectorProperty = options.testSelectorProperty || '_dataTestSelector';
    options.autoTestSelector = options.autoTestSelector || false;

    var isTagless = isBlank(component.tagName);

    var property = 'data-test-' + options.testSelector;
    var dataTestValue = component[property];
    var finalValue = options.autoTestSelector ? dataTestValue || options.testSelectorValue : dataTestValue;

    // To suppress warnings from ember-test-selector for components
    // without a tagName, delete the actual property from the component.
    if (isTagless && component.hasOwnProperty(property)) {
      delete component[property];
    }

    if (finalValue) {
      if (isTagless) {
        set(component, options.testSelectorProperty, finalValue);
      } else {
        component[property] = finalValue;
      }
    }
  }
});