define('ember-do-forms/components/do-form', ['exports', 'ember-do-forms/templates/components/do-form', 'ember-do-forms/utils/has-only-ember-view'], function (exports, _doForm, _hasOnlyEmberView) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var assert = Ember.assert,
      Component = Ember.Component,
      get = Ember.get,
      isEmpty = Ember.isEmpty,
      isPresent = Ember.isPresent,
      service = Ember.inject.service,
      set = Ember.set;


  var DoFormComponent = Component.extend({
    layout: _doForm.default,
    config: service('ember-do-forms/config'),
    tagName: 'form',

    fieldComponent: 'do-field',

    showAllValidations: false,

    init: function init() {
      this._super.apply(this, arguments);
      var submitAction = get(this, 'submit');
      var defaultClasses = get(this, 'config.defaultClasses.form');

      assert('{{do-form}} requires an object to be passed in.', isPresent(get(this, 'object')));

      set(this, 'submit', function (event) {
        event.preventDefault();
        set(this, 'showAllValidations', true);

        if (isPresent(submitAction)) {
          submitAction();
        }
      });

      if (isEmpty(this.classNames) || (0, _hasOnlyEmberView.default)(this.classNames)) {
        this.classNames = this.classNames.concat(defaultClasses);
      }
    },
    update: function update(object, propertyName, value) {
      set(object, propertyName, value);
    }
  });

  DoFormComponent.reopenClass({
    positionalParams: ['object']
  });

  exports.default = DoFormComponent;
});