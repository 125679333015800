define('ember-do-forms/components/ember-do-forms/input-field', ['exports', 'ember-do-forms/templates/components/ember-do-forms/input-field', 'ember-do-forms/utils/set-data-test-selector', 'ember-do-forms/mixins/complex-field'], function (exports, _inputField, _setDataTestSelector, _complexField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var assert = Ember.assert,
      Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      isPresent = Ember.isPresent;


  var InputFieldComponent = Component.extend(_complexField.default, {
    layout: _inputField.default,
    tagName: '',

    controlType: 'text',

    init: function init() {
      this._setTestSelectors();

      // FIXME: data-test-input-field should be removed in next
      // major version as it adds an unnecessary data-test-* attribute
      (0, _setDataTestSelector.default)(this, {
        testSelector: 'input-field',
        testSelectorProperty: '_dataTestSelectorInputField',
        autoTestSelector: get(this, 'config.autoDataTestSelectors'),
        testSelectorValue: get(this, 'propertyName')
      });

      this._super.apply(this, arguments);

      assert('{{ember-do-forms/input-field}} requires an object to be passed in', isPresent(get(this, 'object')));
      assert('{{ember-do-forms/input-field}} requires a propertyName to be passed in', isPresent(get(this, 'propertyName')));

      this._setChildClasses();
    },
    update: function update(object, propertyName, value) {
      set(object, propertyName, value);
    }
  });

  InputFieldComponent.reopenClass({
    positionalParams: ['propertyName']
  });

  exports.default = InputFieldComponent;
});