define('ember-cordova-events/services/ember-cordova/events', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var A = Ember.A,
      Evented = Ember.Evented,
      _Ember$RSVP = Ember.RSVP,
      defer = _Ember$RSVP.defer,
      resolve = _Ember$RSVP.resolve,
      Service = Ember.Service,
      assert = Ember.assert,
      isPresent = Ember.isPresent,
      isBlank = Ember.isBlank,
      on = Ember.on,
      run = Ember.run;


  // from https://cordova.apache.org/docs/en/4.0.0/cordova_events_events.md.html
  var CORDOVA_EVENTS = new A(['deviceready', 'pause', 'resume', 'resign', 'active', 'backbutton', 'menubutton', 'searchbutton', 'startcallbutton', 'endcallbutton', 'volumedownbutton', 'volumeupbutton', 'batterycritical', 'batterylow', 'batterystatus', 'online', 'offline']);

  exports.default = Service.extend(Evented, {
    _listeners: undefined,
    _ready: undefined,
    _readyHasTriggered: false,

    init: function init() {
      this._super();

      this._ready = defer();
      this._setupListeners();
    },
    willDestroy: function willDestroy() {
      this._rejectPendingReadyPromise();
      this._teardownListeners();

      this._super();
    },
    ready: function ready() {
      return this._readyHasTriggered ? resolve() : this._ready.promise;
    },
    on: function on(name, target, method) {
      if (name === 'deviceready' && this._readyHasTriggered) {
        run.join(target, method);
      }

      return this._super(name, target, method);
    },


    _setupReadyPromise: on('deviceready', function () {
      this._readyHasTriggered = true;
      this._ready.resolve();
      this._ready = null;
    }),

    _setupListeners: function _setupListeners() {
      var _this = this;

      var listeners = [];

      assert('listeners have already been set up', isBlank(this._listeners));

      CORDOVA_EVENTS.forEach(function (name) {
        var listener = {
          name: name,
          method: function method(e) {
            _this.trigger(name, e);
          }
        };

        listeners.push(listener);
        document.addEventListener(listener.name, listener.method, true);
      });

      this._listeners = listeners;
    },
    _rejectPendingReadyPromise: function _rejectPendingReadyPromise() {
      if (isPresent(this._ready)) {
        this._ready.reject();
        this._ready = null;
      }
    },
    _teardownListeners: function _teardownListeners() {
      this._listeners.forEach(function (listener) {
        var name = listener.name,
            method = listener.method;

        document.removeEventListener(name, method, true);
      });
    }
  });
});