define('ember-do-forms/components/do-label', ['exports', 'ember-do-forms/templates/components/do-label', 'ember-do-forms/utils/has-only-ember-view', 'ember-do-forms/utils/set-data-test-selector'], function (exports, _doLabel, _hasOnlyEmberView, _setDataTestSelector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      getWithDefault = Ember.getWithDefault,
      service = Ember.inject.service,
      isEmpty = Ember.isEmpty,
      set = Ember.set;


  var DoLabelComponent = Component.extend({
    layout: _doLabel.default,
    config: service('ember-do-forms/config'),

    tagName: 'label',
    attributeBindings: ['for'],

    init: function init() {
      (0, _setDataTestSelector.default)(this, {
        testSelector: 'do-label',
        autoTestSelector: get(this, 'config.autoDataTestSelectors'),
        testSelectorValue: get(this, 'propertyName')
      });

      this._super.apply(this, arguments);
      var classNames = get(this, 'classNames');
      var classes = getWithDefault(this, 'config.defaultClasses', {});

      if (isEmpty(classNames) || (0, _hasOnlyEmberView.default)(classNames)) {
        set(this, 'classNames', classNames.concat(classes.label));
      }
    }
  });

  DoLabelComponent.reopenClass({
    positionalParams: ['labelText']
  });

  exports.default = DoLabelComponent;
});