define('ember-do-forms/components/do-field', ['exports', 'ember-do-forms/templates/components/do-field', 'ember-do-forms/utils/presence', 'ember-do-forms/utils/has-only-ember-view', 'ember-do-forms/utils/set-data-test-selector'], function (exports, _doField, _presence, _hasOnlyEmberView, _setDataTestSelector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var assert = Ember.assert,
      computed = Ember.computed,
      Component = Ember.Component,
      get = Ember.get,
      service = Ember.inject.service,
      isEmpty = Ember.isEmpty,
      isPresent = Ember.isPresent,
      mixin = Ember.mixin,
      set = Ember.set;


  var DoLabelComponent = Component.extend({
    layout: _doField.default,
    config: service('ember-do-forms/config'),
    tagName: 'div',

    controlType: 'text',

    labelComponent: 'do-label',
    controlComponent: 'do-control',
    feedbackComponent: 'do-feedback',
    hintComponent: 'do-hint',

    classNameBindings: ['validationClasses'],

    showValidation: computed.or('showSelfValidation', 'showAllValidations'),
    hasErrors: computed.notEmpty('errors').readOnly(),

    controlId: computed('propertyName', function () {
      return get(this, 'propertyName') + '-' + get(this, 'elementId');
    }),

    // Should work with ember-buffered-proxy and ember-changeset as well
    controlName: computed('objectName', 'propertyName', function () {
      var prop = get(this, 'propertyName');
      var name = get(this, 'objectName') || get(this, 'object.constructor.modelName') || get(this, 'object.content.constructor.modelName') || get(this, 'object._content.constructor.modelName');

      return isPresent(name) ? name + '[' + prop + ']' : prop;
    }).readOnly(),

    // Should work with cp-validations and changeset-validations as well
    errorMessage: computed('errors.[]', function () {
      var firstError = this.get('errors.0') || {};
      if (typeof firstError === 'string') {
        return firstError;
      }
      return firstError.message || firstError.validation;
    }),

    validationClasses: computed('showValidation', 'hasErrors', function () {
      var classes = get(this, 'config.validationClasses');
      var hasErrors = get(this, 'hasErrors');

      if (get(this, 'showValidation')) {
        return (0, _presence.default)(hasErrors ? classes.fieldError : classes.fieldSuccess);
      }
    }).readOnly(),

    controlValidationClasses: computed('showValidation', 'hasErrors', function () {
      var classes = get(this, 'config.validationClasses');
      var hasErrors = get(this, 'hasErrors');

      if (get(this, 'showValidation')) {
        return (0, _presence.default)(hasErrors ? classes.controlError : classes.controlSuccess);
      }
    }).readOnly(),

    init: function init() {
      (0, _setDataTestSelector.default)(this, {
        testSelector: 'do-field',
        autoTestSelector: get(this, 'config.autoDataTestSelectors'),
        testSelectorValue: get(this, 'propertyName')
      });

      this._super.apply(this, arguments);
      var propertyName = get(this, 'propertyName');
      var errorsPath = ('object.' + get(this, 'config.errorsPath')).replace(new RegExp('{PROPERTY_NAME}'), propertyName);
      var defaultClasses = get(this, 'config.defaultClasses.field');

      assert('{{do-field}} requires an object to be passed in', isPresent(get(this, 'object')));
      assert('{{do-field}} requires a propertyName to be passed in', isPresent(propertyName));

      mixin(this, {
        errors: computed(errorsPath, function () {
          return get(this, errorsPath) || [];
        })
      });

      if (isEmpty(this.classNames) || (0, _hasOnlyEmberView.default)(this.classNames)) {
        this.classNames = this.classNames.concat(defaultClasses);
      }
    },
    update: function update(object, propertyName, value) {
      set(object, propertyName, value);
    },
    focusOut: function focusOut() {
      this._super.apply(this, arguments);
      set(this, 'showSelfValidation', true);
    },


    actions: {
      onUpdate: function onUpdate(object, propertyName, value) {
        get(this, 'update')(object, propertyName, value);
      }
    }
  });

  DoLabelComponent.reopenClass({
    positionalParams: ['propertyName']
  });

  exports.default = DoLabelComponent;
});