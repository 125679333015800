define('ember-do-forms/mixins/complex-field', ['exports', 'ember-do-forms/utils/set-data-test-selector'], function (exports, _setDataTestSelector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      isEmpty = Ember.isEmpty,
      service = Ember.inject.service,
      set = Ember.set,
      Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    config: service('ember-do-forms/config'),

    labelClasses: [],
    controlClasses: [],
    feedbackClasses: [],
    hintClasses: [],

    _setTestSelectors: function _setTestSelectors() {
      (0, _setDataTestSelector.default)(this, {
        testSelector: 'do-field',
        testSelectorProperty: '_dataTestSelectorField',
        autoTestSelector: get(this, 'config.autoDataTestSelectors'),
        testSelectorValue: get(this, 'propertyName')
      });

      (0, _setDataTestSelector.default)(this, {
        testSelector: 'do-label',
        testSelectorProperty: '_dataTestSelectorLabel',
        autoTestSelector: get(this, 'config.autoDataTestSelectors'),
        testSelectorValue: get(this, 'propertyName')
      });

      (0, _setDataTestSelector.default)(this, {
        testSelector: 'do-control',
        testSelectorProperty: '_dataTestSelectorControl',
        autoTestSelector: get(this, 'config.autoDataTestSelectors'),
        testSelectorValue: get(this, 'propertyName')
      });

      (0, _setDataTestSelector.default)(this, {
        testSelector: 'do-feedback',
        testSelectorProperty: '_dataTestSelectorFeedback',
        autoTestSelector: get(this, 'config.autoDataTestSelectors'),
        testSelectorValue: get(this, 'propertyName')
      });

      (0, _setDataTestSelector.default)(this, {
        testSelector: 'do-hint',
        testSelectorProperty: '_dataTestSelectorHint',
        autoTestSelector: get(this, 'config.autoDataTestSelectors'),
        testSelectorValue: get(this, 'propertyName')
      });
    },
    _setChildClasses: function _setChildClasses() {
      var defaultClasses = get(this, 'config.defaultClasses');

      if (isEmpty(get(this, 'labelClasses'))) {
        set(this, 'labelClasses', defaultClasses.label);
      }

      if (isEmpty(get(this, 'controlClasses'))) {
        set(this, 'controlClasses', defaultClasses.control);
      }

      if (isEmpty(get(this, 'feedbackClasses'))) {
        set(this, 'feedbackClasses', defaultClasses.feedback);
      }

      if (isEmpty(get(this, 'hintClasses'))) {
        set(this, 'hintClasses', defaultClasses.hint);
      }
    }
  });
});