define('ember-do-forms/utils/has-only-ember-view', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = hasOnlyEmberView;
  var A = Ember.A,
      isArray = Ember.isArray;


  // The classNames on a Component return [] after Ember 2.11,
  // but before that it returns ['ember-view'].
  function hasOnlyEmberView(classNames) {
    if (!isArray(classNames)) {
      return false;
    } else {
      return classNames.length === 1 && A(classNames).includes('ember-view');
    }
  }
});