define('ember-do-forms/components/do-hint', ['exports', 'ember-do-forms/templates/components/do-hint', 'ember-do-forms/utils/has-only-ember-view', 'ember-do-forms/utils/set-data-test-selector'], function (exports, _doHint, _hasOnlyEmberView, _setDataTestSelector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      service = Ember.inject.service,
      isEmpty = Ember.isEmpty;


  var DoHintComponent = Component.extend({
    layout: _doHint.default,
    config: service('ember-do-forms/config'),

    tagName: 'small',
    text: '',

    init: function init() {
      (0, _setDataTestSelector.default)(this, {
        testSelector: 'do-hint',
        autoTestSelector: get(this, 'config.autoDataTestSelectors'),
        testSelectorValue: get(this, 'propertyName')
      });

      this._super.apply(this, arguments);
      var defaultClasses = get(this, 'config.defaultClasses.hint');

      if (isEmpty(this.classNames) || (0, _hasOnlyEmberView.default)(this.classNames)) {
        this.classNames = this.classNames.concat(defaultClasses);
      }
    }
  });

  DoHintComponent.reopenClass({
    positionalParams: ['text']
  });

  exports.default = DoHintComponent;
});