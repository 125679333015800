define('ember-do-forms/components/do-feedback', ['exports', 'ember-do-forms/templates/components/do-feedback', 'ember-do-forms/utils/has-only-ember-view', 'ember-do-forms/utils/set-data-test-selector'], function (exports, _doFeedback, _hasOnlyEmberView, _setDataTestSelector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      service = Ember.inject.service,
      isEmpty = Ember.isEmpty;


  var DoFeedbackComponent = Component.extend({
    layout: _doFeedback.default,
    config: service('ember-do-forms/config'),
    tagName: '',

    wrapperTagName: 'div',

    showFeedback: false,

    init: function init() {
      (0, _setDataTestSelector.default)(this, {
        testSelector: 'do-feedback',
        autoTestSelector: get(this, 'config.autoDataTestSelectors'),
        testSelectorValue: get(this, 'propertyName')
      });

      this._super.apply(this, arguments);
      var defaultClasses = get(this, 'config.defaultClasses.feedback');

      if (isEmpty(this.classNames) || (0, _hasOnlyEmberView.default)(this.classNames)) {
        this.classNames = this.classNames.concat(defaultClasses);
      }
    }
  });

  DoFeedbackComponent.reopenClass({
    positionalParams: ['message']
  });

  exports.default = DoFeedbackComponent;
});