define('ember-do-forms/components/do-control', ['exports', 'ember-do-forms/templates/components/do-control', 'ember-do-forms/utils/has-only-ember-view', 'ember-do-forms/utils/set-data-test-selector'], function (exports, _doControl, _hasOnlyEmberView, _setDataTestSelector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component,
      get = Ember.get,
      service = Ember.inject.service,
      isEmpty = Ember.isEmpty;


  var DoControlComponent = Component.extend({
    layout: _doControl.default,
    tagName: '',

    config: service('ember-do-forms/config'),

    controlType: 'text',

    // For one-way-select
    promptIsSelectable: false,

    oneWayControl: computed('controlType', function () {
      return 'one-way-' + get(this, 'controlType');
    }).readOnly(),

    init: function init() {
      (0, _setDataTestSelector.default)(this, {
        testSelector: 'do-control',
        autoTestSelector: get(this, 'config.autoDataTestSelectors'),
        testSelectorValue: get(this, 'propertyName')
      });

      this._super.apply(this, arguments);
      var defaultClasses = get(this, 'config.defaultClasses.control');

      if (isEmpty(this.classNames) || (0, _hasOnlyEmberView.default)(this.classNames)) {
        this.classNames = this.classNames.concat(defaultClasses);
      }
    }
  });

  DoControlComponent.reopenClass({
    positionalParams: ['controlType', 'value']
  });

  exports.default = DoControlComponent;
});