define('ember-do-forms/components/ember-do-forms/feedback-wrapper', ['exports', 'ember-do-forms/templates/components/ember-do-forms/feedback-wrapper'], function (exports, _feedbackWrapper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _feedbackWrapper.default
  });
});