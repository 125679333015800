define('ember-do-forms/utils/presence', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = presence;
  var isPresent = Ember.isPresent;
  function presence(value) {
    return isPresent(value) ? value : null;
  }
});