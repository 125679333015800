define('ember-do-forms/components/do-fields', ['exports', 'ember-do-forms/templates/components/do-fields'], function (exports, _doFields) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var assert = Ember.assert,
      Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      isPresent = Ember.isPresent;


  var DoFieldsComponent = Component.extend({
    layout: _doFields.default,
    tagName: '',

    fieldComponent: 'do-field',

    init: function init() {
      this._super.apply(this, arguments);

      assert('{{do-fields}} requires an object to be passed in.', isPresent(get(this, 'object')));
    },
    update: function update(object, propertyName, value) {
      set(object, propertyName, value);
    }
  });

  DoFieldsComponent.reopenClass({
    positionalParams: ['object']
  });

  exports.default = DoFieldsComponent;
});