define('ember-do-forms/instance-initializers/ember-do-forms/initializer', ['exports', 'ember-get-config', 'ember-do-forms/utils/config-defaults'], function (exports, _emberGetConfig, _configDefaults) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  var merge = Ember.merge,
      set = Ember.set;
  function initialize(application) {
    var doFormConfig = merge((0, _configDefaults.default)(), _emberGetConfig.default['ember-do-forms']);
    var configService = application.lookup('service:ember-do-forms/config');

    Object.keys(doFormConfig).forEach(function (key) {
      set(configService, key, doFormConfig[key]);
    });
  }

  exports.default = {
    name: 'ember-do-forms/initializer',
    initialize: initialize
  };
});